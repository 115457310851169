(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:OrganisationUsers
   *
   * @description
   *
   */
  angular
  .module('neo.services')
  .factory('OrganisationUsers', OrganisationUsers);

  function OrganisationUsers($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/organisation/:id/users', {id: '@_id'});
  }
}());
